@import "~bootstrap/scss/bootstrap";
$base-color: #015d92;
$primary-color: #015d92;
$secondary-color: #eff4f8;
$white: #fff;
$green: green;
// --tw-border-opacity: 

body {
  height: 100vh;
}

#root {
  background: #EFF4F8
}

#root>.container-fluid1 {
  background: white;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.image-gallery-slide .image-gallery-description {
  background: rgba(0, 0, 0, .7);
  top: 0;
  max-height: 70px;
  width: 100%;
  vertical-align: middle;
  text-align: center;
  align-items: center;
}

.max-h-10 {
  max-height: 15rem;
  overflow: auto;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: $secondary-color;
}

.max-h-5 {
  max-height: 78px;
  min-height: 78px;
}

.nav-item,
.nav-link,
.dropdown-item {
  color: $secondary-color !important;
  margin-bottom: 3px;
}

.single-site-tabs .nav-link {
  text-decoration: underline;
  padding-bottom: 10px !important;
  border-bottom-style: solid !important;
}

h6.MuiTypography-root {
  font-size: x-small;
  color: $secondary-color;
}

.nav-link:focus,
.dropdown-item:focus,
.nav-link:hover,
.dropdown-item:hover,
.nav-link.active,
.dropdown-item.active,
.dropdown-item:active {
  background-color: $secondary-color !important;
  color: $base-color !important;
}

button.Mui-selected {
  background-color: $secondary-color;
  color: $base-color;
}

.MuiPaper-root,
.css-bg2c96-MuiPaper-root-MuiCard-root {
  background-color: transparent !important;
  box-shadow: none !important;
}

.tab-pane-content>.MuiBox-root {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

.user-avatar {
  background-color: $base-color;
}

.user-avatar svg {
  color: $base-color;
}

.min-h-53vh {
  min-height: 55vh;
}

.min-h-90vh {
  min-height: 90vh;
}

.header-nav {
  background-color: $base-color;
}

.container-fluid1 .parent-container {
  min-height: calc(100vh - 126px);
}

.footer {
  background-color: $secondary-color;
  border-bottom: 8px $base-color solid;
}

.handsontable tbody th.ht__active_highlight,
.handsontable thead th.ht__active_highlight {
  background-color: $secondary-color;
}

.handsontable td.area:before {
  background: $secondary-color;
}

.w-50 {
  width: 50%;
}

.w-46 {
  width: 46%;
}

.w-40 {
  width: 40%;
}

.w-30 {
  width: 30%;
}


.w-20 {
  width: 24%;
}

.nav-ul li {
  padding-left: 5px;
  padding-right: 5px;
}

.mt-0-7 {
  margin-top: .7rem !important;
}

.right-modal .modal-dialog-container {
  margin: unset !important;
  margin-left: auto !important;
  max-width: 670px !important;
}

.right-modal .modal-dialog-container .modal-body {
  max-height: 81.5vh;
  overflow: auto;
}

.right-modal .modal-dialog-container .modal-footer {
  max-height: 81.5vh;
  overflow: auto;
}

.right-modal .modal-dialog-container .modal-content {
  min-height: 100vh !important;
  border-radius: 0 !important;
}

.home-content select.form-control,
.right-modal .modal-dialog-container .modal-content select.form-control {
  appearance: auto !important;
}

select.qc_flag_comment.form-control {
  padding: 6px 1px;
}

.w-10 {
  width: 5rem !important;
  padding: 5px !important;
}

.img-fluid.madera-logo {
  max-width: 250px;
}

.css-ahj2mt-MuiTypography-root {
  font-weight: 400 !important;
  font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans" !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.mvh-100 {
  min-height: 100vh;
}

.mvh-90 {
  min-height: 90vh;
}

.loader-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.62);
  z-index: 999999;
}

.no-record-container {
  position: absolute;
  margin-top: -45vh;
  font-size: 20px;
  font-weight: 500;
  width: 83%;
}

.select2-container--open {
  z-index: 999999;
}


.multiselect-native-select {
  min-width: 74%;
}

.multiselect-container.dropdown-menu.show,
.multiselect-native-select .btn-group {
  min-width: 100%;
}

.multiselect-filter.d-flex {
  padding: 10px;
}


.select2-container .select2-selection--single {
  min-height: 38px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 38px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 38px;
}

.shadow-none {
  box-shadow: none;
}

.dropdown-item.active,
.dropdown-item:active {
  color: inherit;
}

.multiselect-native-select .form-check-label {
  color: #000;
}

#myTab .nav-item,
#exampleModal .nav-item,
#exampleModal .nav-link,
#myTab .nav-link,
#exampleModal .dropdown-item {
  color: black !important;
  text-align: left !important;
}

#exampleModal .dropdown-toggle {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  text-align: left !important;
  box-shadow: 1px 1px 1px 1px #ced4da;
}

#exampleModal .dropdown-toggle::after {
  display: none;
}

#exampleModal .dropdown-toggle .multiselect-selected-text {
  text-align: left !important;
}

.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select>select.mobile-device:focus+.dropdown-toggle {
  outline: none !important;
}

#exampleModal .filter-option-inner-inner {
  color: #333;
}

.overlay {
  background: #e9e9e9;
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.8;
  z-index: 1032;
  height: 100%;
  padding: 30px
}

.modalL {
  display: none;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, .8) url('http://i.stack.imgur.com/FhHRx.gif') 50% 50% no-repeat;
}

body.loading .modalL {
  overflow: hidden;
}

body.loading .modalL {
  display: block;
}

.display-columns .multiselect-native-select .multiselect-container.dropdown-menu.show {
  max-width: 320px;
  margin-left: 4.5rem !important;
}

.login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.handsontable td {
  max-width: 40vw !important;
  vertical-align: middle;
  padding: 6px;
}

.min-h-25 {
  min-height: 25px !important;
}

.table-fit {
  overflow: hidden;
}

.table-fit.media-custom-height,
.table-fit.media-custom-height .overflow-auto1>.ht_master:first-child>.wtHolder {
  height: calc(100vh - 240px) !important;
}

.table-fit.table-custom-height,
.table-fit.table-custom-height .overflow-auto1>.ht_master:first-child>.wtHolder {
  height: calc(100vh - 260px) !important;
}

.table-fit.table-comment-custom-height,
.table-fit.table-comment-custom-height .overflow-auto1>.ht_master:first-child>.wtHolder {
  height: calc(100vh - 290px) !important;
}

.table-fit.table-log-custom-height,
.table-fit.table-log-custom-height .overflow-auto1>.ht_master:first-child>.wtHolder {
  height: calc(100vh - 290px) !important;
}

.nav-ul li {
  padding-top: 2px;
}


.MuiBox-root.css-19kzrtu {
  padding: unset;
  padding-top: 24px;
}

.handsontable .autocompleteEditor.handsontable .wtHolder {
  border: none !important;
}

.handsontable .autocompleteEditor.handsontable table {
  border: none !important;
}

.handsontable .autocompleteEditor.handsontable {
  padding-right: 0 !important;
  border: none !important;
  box-shadow: -3px 5px 5px #ccc !important;
}

.gap-2 {
  gap: 5px;
}

.fas {
  display: none !important;
}

.meta-info-table-parent {
  display: none;
}

.popover {
  max-width: 450px !important;
}
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow, 
.bs-popover-bottom>.popover-arrow {
  transform: none !important;
    left: calc(100% - 43px)!important;
}

.meta-info-table table th,
.meta-info-table table td {
  padding: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.img-container img {
  // max-width: 150px;
  // max-height: 150px;
  // min-height: 150px;
  max-width: 100%;
  height: auto;
}

.media-tab .img-container img {
  // max-width: 350px;
  // max-height: 350px;
  // min-height: 350px;
  max-width: 100%;
  height: auto;

}

.middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

hr {
  margin: auto;
  width: 100%;
}

.other-tab-header {
  // margin-bottom: -5rem!important;
}

.other-tab-container-header {
  // margin-top: 5rem!important;
}

.single-site-tabs .nav-link active,
.single-site-tabs .nav-link.active,
.single-site-tabs .nav-link:hover,
.single-site-tabs .dropdown-item:hover {
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
}

.single-site-tabs .nav-link active,
.single-site-tabs .nav-link.active {
  text-decoration: none !important;
}

.filter-site-media .dropdown-item {
  color: black !important;
}

form.auth-form {
  max-width: 350px;
  width: 100%;
}

.map-overlay-filter {
  top: 0px;
}

.task-tables .meta-info-table table th,
.task-tables .meta-info-table table td {
  padding: 5px !important;
}

.comments-table-container thead th {
  vertical-align: middle !important;
}

.comments-table-container.table>:not(caption)>*>* {
  padding: .1rem .1rem
}

a.add-task-comment-dialog,
a.add-comment-dialog {
  padding-left: 1.4rem !important;
  padding-right: 1.4rem !important;
}

#chartdiv canvas:last-child {
  // display: none !important;
}

#exportdiv {
  // padding: 5px;
  // height: 40px;
  // border-bottom: 1px solid #eee;
  // width: 10% !important;
}

#exportdiv1 .am5exporting-icon {
  // width: 100% !important;
  // visibility: hidden;
  right: 11rem;
  margin-top: 6.5rem;
  visibility: hidden;
}

#exportdiv1 .am5exporting-list.am5exporting-align-right {
  margin-right: 11.2rem !important;
  margin-top: 6.5rem !important;
}


#chartdiv {
  width: 100%;
  min-height: 400px;
}

.notification_recipient_container .multiselect.dropdown-toggle.custom-select {
  text-align: left !important;
  color: #000 !important;
}

.notification_recipient_container .multiselect.dropdown-toggle.custom-select::after {
  visibility: hidden !important;
}

.text-red {
  color: red;

}

.additional-filter {
  background-color: #f0f0f0 !important;
  margin-left: -1rem !important;
  margin-right: -1rem !important;
  margin-bottom: 1rem !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  padding-bottom: 1px !important;
  padding-top: 1rem !important;
  margin-top: -1rem !important;
}

.alert-with-icon {
  top: 0.6rem !important;
}


.table-scroll {
  overflow: auto;
}

.comments-table-container tr th,
.comments-table-container tr td {
  padding: 5px !important;

}

.word-break {
  word-break: break-all;
}

#exampleAddCommentModal{
  // z-index: 99999;
  padding: 10px
}

#exampleAddCommentModal .modal-dialog-container {
  max-width: 1150px !important;
  margin: 0 auto;
  min-height: 93.5vh !important;
  width: 100%;
  max-height: 93.5vh !important;
}

#exampleAddCommentModal .modal-dialog-container .modal-content {
  min-height: 94vh !important;
  max-height: 94vh !important;
  // overflow: auto;
}

#exampleAddCommentModal .modal-dialog-container .modal-body {
  overflow: auto;
}

#exampleAddCommentModal .modal-dialog-container .modal-footer {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 140px) !important;
}

.modal-header .close span {
  position: relative;
  top: -3px;
}

.datepicker-days {
  padding: 10px !important;
}

.comments-container .card-footer .date button {
  border-radius: 10px !important;
}

.btn-danger-custom {
  background-color: #bf7272 !important;
  color: #fff !important;
}

.comments-container .card-footer .btn {
  // height: 2rem !important;
  border-radius: 1px !important;
}

.completed-data p {
  // text-decoration: underline;
  // font-weight: 600;
}

.in-progress-data .card-footer,
.information-data .card-footer,
.completed-data .card-footer {
  background-color: #fff;
  min-height: 30px !important;
}


.in-progress-data .card-body,
.information-data .card-body,
.completed-data .card-body {
  min-height: 4.5rem !important;
}

.in-progress-data .card,
.information-data .card,
.completed-data .card {
  gap: 3px;
  border-radius: 0;
  // min-width: 330px !important;
  /*max-width: 330px;*/
  max-width: 100%;
}

.circle-avatar-plain {
  height: 28px;
  width: 28px;
  line-height: 28px;
  border-radius: 50%;
  font-size: 12px;
  font-family: roboto, arial;
  color: gray;
  text-align: center;
  background: transparent;
  /*Align div in centre of page*/
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  cursor: pointer;
}

.circle-avatar {
  height: 28px;
  width: 28px;
  line-height: 28px;
  border-radius: 50%;
  font-size: 12px;
  font-family: roboto, arial;
  color: white;
  text-align: center;
  background: $primary-color;
  /*Align div in centre of page*/
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  cursor: pointer;
}
.circle-avatar { margin-left: -12px;}
.circle-avatar:first-child { margin-left: 0;}

.border-1 {
  border: 1px solid gray;
  border-radius: 2%;
}

.strong {
  font-weight: 500;
}

.aquamarine {
  background-color: aquamarine;
}

.lightyellow {
  background-color: yellowgreen;
}

.lightgreen {
  background-color: lightgreen;
}

.lightblue {
  background-color: lightblue;
}

.lightorange {
  background-color: lightcoral;
}

.red {
  background-color: red;
}

.orange {
  background-color: orange;
}

.green {
  background-color: green;
}

.gray {
  background-color: gray;
}

.lightgray {
  background-color: lightgray;
}

.pink {
  background-color: pink;
}

.blue {
  background-color: #0d6efd;
}

.view-completed-task {
  margin-bottom: 0 !important;
}

.cursor-pointer {
  cursor: pointer !important;
}


.comments-container input[type='radio'] {
  // width: 20px;
  // height: 20px;
  // border: 2px solid #747474;
  // border-radius: 50%;
  // outline: none;
  // opacity: 0.6;
  // transition: 0.3s;
}

.comments-container input[type='radio']:hover:before {
  // box-shadow: 0px 0px 0px 12px rgba(80, 80, 200, 0.2);
  // border-radius: 50%;
  // opacity: 1;
  // background: green;
}

.comments-container input[type='radio']:before {
  // content: '';
  // display: block;
  // width: 55%;
  // height: 63%;
  // margin: 20% auto;
  // border-radius: 50%;
}

.comments-container input[type='radio']:checked:before {
  // background: green;
}

.filter-count-badge {
  position: absolute !important;
  background: red !important;
  border-radius: 50% !important;
  min-height: 1.5rem;
  min-width: 1.5rem;
  top: 5rem !important;
}

.filter-count-badge span {
  // margin-top: 0.4rem;
  margin-bottom: 0rem !important;
  align-items: center !important;
  display: flex !important;
  margin-left: 1px !important;
  margin-top: 2px !important;
}

/*.task-comments {
  max-height: 42vh;
  overflow: auto;
}*/

.task-comments .card-body {
  padding: 7px 10px;
}

.preset-data .card-body {
  padding: 7px 10px;
}

/*.completed-task-comments {
  max-height: 50vh;
  overflow: auto;
}*/

.manage-presets-container,
.manage-comments-container {
  background: #f0f0f0;
  padding: 10px;
  border-radius: 10px;
  max-height: calc(100vh - 49vh) !important;
  overflow-y: auto;
}

.checkbox:checked,
.radio:checked {
  --tw-border-opacity: 1;
  border-color: $green;
  background-repeat: no-repeat;
}

.checkbox:checked,
.radio:hover,
.radio:checked {
  background-color: $green;
  background-image: url(../../public/media/check.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.checkbox:focus-visible,
.radio,
.radio:focus-visible {
  border-radius: 9999px;
}

.checkbox,
.radio {
  height: 1.25rem;
  width: 1.25rem;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-position: 50%;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  -webkit-transition-duration: .15s;
  transition-duration: .15s;
  border: solid 2px $green;
}

.checkbox{
  border-radius: 4px;
  width: 18px;
  height: 18px;
  
}
.edit-task-calendar{
  border: none;
  padding: 0;
  background: none;
}

.fs-7{
  font-size: 14px;
}

.form-switch .form-check-input.check-utc{
  padding: 12px;
  margin-left: 10px;
  width: 3em;
}
.topLeftSelectionHandle,
.bottomRightSelectionHandle,
.topLeftSelectionHandle-HitArea,
.bottomRightSelectionHandle-HitArea
{
  display: none !important;
}
// .multiselect-container .multiselect-filter > input.multiselect-search {
//   border-bottom-right-radius: 0.25rem;
//   border-bottom-left-radius: 0.25rem;
// }


/*@media (max-width: 1540px) {
  #exampleAddCommentModal .modal-dialog-container .modal-footer {
    overflow-y: auto;
    max-height: 80vh;
    overflow-x: hidden;
  }
}
@media (max-width: 1441px) {
  #exampleAddCommentModal .modal-dialog-container .modal-footer {
    overflow-y: auto;
    max-height: 76vh;
    overflow-x: hidden;
  }
}
*/
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (max-width: 1399px) {

  .data_header {
    min-height: inherit !important;

  }

  .filter-count-badge {
    top: 7.5rem !important;
  }

  #exampleAddCommentModal .modal-dialog-container .modal-content span.comments-site-name {
    word-break: break-all;
  }

  #exampleAddCommentModal .modal-dialog-container .modal-content .modal-subtitle.w-75.d-flex {
    justify-content: end;
    gap: 10px;
  }
}

@media (min-width: 992px) {
  .table-filter {
    padding: 0px 16px 0px 0px !important;
  }

  .in-progress-data .card,
  .information-data .card,
  .completed-data .card {
    // min-width: 330px !important;
    // max-width: 330px;
    margin-bottom: 10px;

  }
  .in-progress-data .card:last-child ,
  .information-data .card:last-child,
  .completed-data .card:last-child {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 1024px) {

  .in-progress-data .card,
  .information-data .card,
  .completed-data .card {
    // min-width: 330px !important;
    /*max-width: 330px;*/
    max-width: 100%;
  }

  /*#exampleAddCommentModal .modal-dialog-container .modal-footer {
    overflow-y: auto;
    max-height: 70vh;
    overflow-x: hidden;
  }*/
}

@media screen and (max-width: 991px) {

  /*#exampleAddCommentModal .modal-dialog-container .modal-footer {
    overflow-y: auto;
    max-height: 68vh;
    overflow-x: hidden;
  }*/

  .d-flex {
    display: block !important;
  }

  .right-profile-icon {
    margin-right: 0px !important;
    padding-right: 10px !important;
  }

  .pagination-container {
    display: block !important;
    width: 100%;
    text-align: center;
  }

  .pagination-container nav ul {
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
  }

  .right-profile-icon {
    margin-left: 0px !important
  }

  .right-modal .modal-dialog-container {
    max-width: 375px !important;
  }

  .sorting-pagination.gap-5,
  .pagination-block.gap-5,
  .pagination-mobile.gap-5 {
    gap: 1rem !important;
  }

  .table-filter {
    padding: 0px !important;
  }

  .navbar-collapse#navbarSupportedContent {
    position: fixed;
    display: block;
    top: 0px;
    left: 0px;
    max-width: 320px;
    width: 100%;
    background: #fff;
    height: 100%;
    z-index: 999;
    padding: 20px 20px 20px 20px;
    transform: translateX(-100%);
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
  }

  .navbar-collapse.show#navbarSupportedContent {
    transform: translateX(0);
  }

  .navbar-light #navbarSupportedContent .navbar-nav a.nav-link {
    color: #212529 !important;
  }

  .navbar-light #navbarSupportedContent .navbar-nav a.nav-link:hover {
    background: transparent !important;
  }

  .navbar-light #navbarSupportedContent .navbar-nav li.nav-item {
    margin-bottom: 15px;
    padding: 5px 0px;
  }

  .img-fluid.madera-logo {
    max-width: 200px;
  }

  .css-ahj2mt-MuiTypography-root {
    font-weight: 500 !important;
  }

  .navbar-light .navbar-toggler-icon {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDI0IDE4Ij4NCiAgPHBhdGggaWQ9IlBhdGhfMTUiIGRhdGEtbmFtZT0iUGF0aCAxNSIgZD0iTTQsN0gyNk00LDE1SDI2TTQsMjNIMjYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zIC02KSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLXdpZHRoPSIyIi8+DQo8L3N2Zz4NCg==);
  }

  .header-nav .navbar-toggler {
    border-color: transparent;
  }

  .header-nav .navbar-toggler:focus {
    box-shadow: none;
  }


  nav.header-nav {
    padding: 0 !important;
    height: max-content;
    position: relative;
    bottom: 5px;
  }
}

@media screen and (min-width: 992px) {
  .modal-subtitle {
    justify-content: flex-end !important;
    gap: 15px;
  }
}

@media screen and (max-width: 991px) {
  .mobile-logo img {
    width: 105px;
  }
}

@media (min-width: 1440px) {

  #simple-tabpanel-2 .MuiBox-root:first-child,
  #simple-tabpanel-3 .MuiBox-root:first-child {
    margin-top: -80px !important;

  }

  .manage-presets-container {
    max-height: calc(100vh - 47vh) !important;
  }

}

@media (max-width: 768px) {

  /*#exampleAddCommentModal .modal-dialog-container .modal-footer {
    overflow-y: auto;
    max-height: 60vh;
    overflow-x: hidden;
  }*/

  #exampleAddCommentModal  .modal-header {
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-start !important;
  }

  #exampleAddCommentModal  .modal-title {
    min-width: 100% !important;
    margin-bottom: 10px !important;
  }

  .modal-subtitle {
    justify-content: space-between !important;
    min-width: 100% !important;
  }

  .modal-subtitle label {
    min-width: 65px;
  }

  .gap-5 {
    gap: 1rem !important;
  }
}

.row.table-scroll.tasks-parent-container {
  width: auto !important;
}

@media (max-width: 767.98px) {


  .md-flex-col {
    flex-direction: column;
  }

  .w-50 {
    min-width: 100% !important;
  }

  #exampleAddCommentModal .modal-dialog-container {
    min-width: 95% !important;
    
  }

  #exampleAddCommentModal .modal-dialog-container .modal-body {
    padding: 0;
  }

  .mobile-tabs {
    width: 100vw;
    overflow-x: auto;
    padding-bottom: 8px;
    display: flex;
  }

  .dropdown-menu {
    margin: 0 !important;
    font-size: var(--bs-dropdown-font-size) !important;
    color: var(--bs-dropdown-color) !important;
    text-align: left !important;
    list-style: none !important;
    background-color: var(--bs-dropdown-bg) !important;
    background-clip: padding-box !important;
    border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color) !important;
    border-radius: var(--bs-dropdown-border-radius) !important;
  }

  .map-overlay-filter {
    top: 50px;
  }

  .view-by-filter {
    display: block;
  }

  .view-by-filter label {
    margin: 0px !important;
  }

  .w-25.label-title {
    width: 100% !important;
  }

  #exportdiv {
    min-width: 55% !important;
  }

  #exampleAddCommentModal .modal-dialog-container .modal-footer {
    max-height: calc(100vh - 300px) !important;
  }

  .popover {
    max-width: 300px !important;
  }
  #exampleAddCommentModal .modal-title{
    padding-right: 30px;
  }

  #exampleAddCommentModal .select-flag{
    width: 100%;
  }
  #exampleAddCommentModal .rounded-circle.task-info-icon {
    flex:none;
  }
  #exampleAddCommentModal .close-comment{
    position: absolute;
    right:15px;
    top:12px;
    font-size:32px;
  }
  
 
}

@media (max-width: 425px) {

  .in-progress-data .card,
  .information-data .card,
  .completed-data .card {
    min-width: auto !important;
    // max-width: 330px;
  }

  .filter-count-badge {
    top: 11rem !important
  }
}